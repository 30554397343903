var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-list',{attrs:{"heading":_vm.heading,"headings":[
    _vm.$t('fields.name'),
    _vm.$tc('models.company'),
    _vm.$t('misc.completion'),
    'search'
  ],"sort":[
    { prop: 'name', type: false },
    { prop: 'companyName', type: false },
    { prop: 'progress', prop2: 'progressPercentage', type: 'level2' }
  ],"items":_vm.buildings,"search_selectors":['name', 'companyName']},scopedSlots:_vm._u([(_vm.$slots.actions)?{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true}:null,{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.name,"info":props.item.description}}),_c('ui-list-data',{attrs:{"type":"tag","text":props.item.company.name}}),[(typeof _vm.getPercentage(props.item) === 'number')?_c('ui-list-data-progress',{attrs:{"percentage":_vm.getPercentage(props.item),"progressDetails":props.item.progress}}):_vm._e(),(_vm.getPercentage(props.item) === false)?_c('ui-list-data',{attrs:{"text":_vm.$t('labels.course.no_users_assigned')}}):_vm._e()],_c('ui-list-data',{attrs:{"small":""}},[_c('ui-button',{attrs:{"type":"green","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
            name: 'buildings-show',
            params: { id: props.item.id }
          })}}},[_vm._v(_vm._s(_vm.$t('actions.show_content')))]),_c('ui-button',{staticClass:"ml-2",attrs:{"type":"white","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
            name: 'buildings-users',
            params: { id: props.item.id }
          })}}},[_vm._v(_vm._s(_vm.$tc('labels.user.building_admin', 2)))]),_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'CompanyAdmin', 'BuildingAdmin']}},[_c('ui-button',{staticClass:"ml-2",attrs:{"type":"grey","size":"small","uppercase":""},on:{"click":function($event){return _vm.$router.push({
              name: 'buildings-edit',
              params: { id: props.item.id }
            })}}},[_vm._v(_vm._s(_vm.$t('actions.edit')))])],1)],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }